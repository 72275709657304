<template>
    <v-tabs v-model="tab">
        <v-tab value="one">{{ $t("fmission.level1") }}</v-tab>
        <v-tab value="two">{{ $t("fmission.level2") }}</v-tab>
    </v-tabs>

    <v-card-text>
        <v-tabs-window v-model="tab">
            <v-tabs-window-item value="one">
                <v-container>
                    <v-form ref="forml1">
                        <v-row>
                            <v-col cols="12" sm="6" lg="4" v-for="category in fligh_missions_level1"
                                :key="category.key">
                                <v-checkbox v-model="selected" name="category.name" :value="category.key"
                                    :label="$t(category.name)"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-tabs-window-item>

            <v-tabs-window-item value="two">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" lg="4" v-for="category in fligh_missions_level2" :key="category.key">
                            <v-checkbox v-model="selected" name="category.name" :value="category.key"
                                :label="$t(category.name)"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
            </v-tabs-window-item>

        </v-tabs-window>
    </v-card-text>
</template>

<script>
import { loadMissionDataDb, updateMissionDatatDb } from '@/helpers/db/fmissionDb'
export default {
    data() {
        return {
            selected: [],
            tab: "two",
            loading: false,
            fligh_missions_level1: [
                { key: "l1_1", name: 'fmission.unfold_glider' },
                { key: "l1_2", name: 'fmission.inflate_and_run' },
                { key: "l1_3", name: 'fmission.slalom' },
                { key: "l1_4", name: 'fmission.brake_run' },
                { key: "l1_5", name: 'fmission.mavie' },
                { key: "l1_6", name: 'fmission.takeoff' },
                { key: "l1_7", name: 'fmission.flight_with_direction_changes' },
                { key: "l1_8", name: 'fmission.takeoff_stop' },
                { key: "l1_9", name: 'fmission.takeoff_transversal_wind' },
                { key: "l1_10", name: 'fmission.takeoff_wrong_calote' },
                { key: "l1_11", name: 'fmission.takeoff_inflates_method' },
                { key: "l1_12", name: 'fmission.landing_techniques' },
                { key: "l1_13", name: 'fmission.landing_urgency' },
                { key: "l1_14", name: 'fmission.rescue_theory' },
                { key: "l1_15", name: 'fmission.flight_with_direction_changes' },
                { key: "l1_16", name: 'fmission.lines_cleaning' },
                { key: "l1_17", name: 'fmission.packing_techniques' },
                { key: "l1_18", name: 'fmission.theoretical_test' },
            ],
            fligh_missions_level2: [
                { key: "l2_1", name: 'fmission2.tandem' },
                { key: "l2_2", name: 'fmission2.urgency_landing' },
                { key: "l2_3", name: 'fmission2.takeoff_place' },
                { key: "l2_4", name: 'fmission2.back_inflate' },
                { key: "l2_5", name: 'fmission2.roll' },
                { key: "l2_6", name: 'fmission2.huit' },
                { key: "l2_7", name: 'fmission2.turns' },
                { key: "l2_8", name: 'fmission2.deepstall' },
                { key: "l2_9", name: 'fmission2.speedrange' },
                { key: "l2_10", name: 'fmission2.speedbar' },
                { key: "l2_11", name: 'fmission2.neg_pos_brake' },
                { key: "l2_12", name: 'fmission2.weightshift' },
                { key: "l2_13", name: 'fmission2.backriser' },
                { key: "l2_14", name: 'fmission2.asym_collapse' },
                { key: "l2_15", name: 'fmission2.ears' },
                { key: "l2_16", name: 'fmission2.bstall' },
                { key: "l2_17", name: 'fmission2.spiral' },
                { key: "l2_18", name: 'fmission2.instruments' },
                { key: "l2_19", name: 'fmission2.soaring' },
                { key: "l2_20", name: 'fmission2.thermal' },
                { key: "l2_21", name: 'fmission2.landing_approach' },
                { key: "l2_22", name: 'fmission2.precision_landing' },
                { key: "l2_23", name: 'fmission2.backriser_landing_theory' },
                { key: "l2_24", name: 'fmission2.backriser_landing' },
                { key: "l2_25", name: 'fmission2.slope_landing' },
                { key: "l2_26", name: 'fmission2.touch_and_go' },
                { key: "l2_27", name: 'fmission2.exam_program' },
            ],
        }
    },
    method: {
    },
    mounted() {
        this.loading = true
        loadMissionDataDb().then((data) => { this.selected = data.selected; this.loading = false })
    },
    watch: {
        selected(val) {
            updateMissionDatatDb({ selected: val }).then(() => { this.loading = false })
        }
    }
}
</script>
