import { createRouter, createWebHashHistory } from 'vue-router'
import UIHome from '@/views/UIHome.vue'
import FlyDays from '@/views/FlightDays.vue'
import PilotProfile from '@/views/FlightMissions.vue'
import PilotMaterial from '@/views/PilotMaterial.vue'
import FlightDiary from '@/views/FlightDiary.vue'
import SettingsPage from '@/views/SettingsPage.vue'
import PersonalData from '@/views/PersonalData.vue'
import RegisterPage from '@/views/RegisterPage.vue'
import LoginPage from '@/views/LoginPage.vue'
import { getAuth } from 'firebase/auth'
import DataPolicy from '@/views/DataPolicy.vue'

const routes = [
  {
    path: '/',
    name: 'Accueil',
    meta: {
      requiresAuth: true,
    },
    component: UIHome
  },
  {
    path: '/myflightdays',
    name: 'Les journées de vols',
    meta: {
      requiresAuth: true,
    },
    component: FlyDays
  },
  {
    path: '/myflightmissions',
    name: 'Mon Mes missions de vol',
    meta: {
      requiresAuth: true,
    },
    component: PilotProfile
  },
  {
    path: '/myflightdiary',
    name: 'Mon carnet de fol',
    meta: {
      requiresAuth: true,
    },
    component: FlightDiary
  },
  {
    path: '/myflightmaterial',
    name: 'Mon matériel',
    meta: {
      requiresAuth: true,
    },
    component: PilotMaterial
  },
  {
    path: '/mypersonaldata',
    name: 'Mes données personnelles',
    component: PersonalData
  },
  {
    path: '/settings',
    name: ' Paramètres',
    meta: {
      requiresAuth: true,
    },
    component: SettingsPage
  },
  {
    path: '/login',
    name: ' Se connecter',
    component: LoginPage
  },
  {
    path: '/register',
    name: ' S\'enregister',
    component: RegisterPage
  },
  {
    path: '/datapolicy',
    name: 'Data policy',
    component: DataPolicy
  }
]

const app_router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Navigation guard
app_router.beforeEach((to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const auth = getAuth();

  // Check if user is authenticated
  const unsubscribe = auth.onAuthStateChanged(user => {
    // If route requires auth and user is not authenticated
    if (requiresAuth && !user) {
      next({ path: '/login' }); // redirect to login
    } else if (to.path == "/login" && user){
      next({path:'/'});
    } else {
      next(); // proceed to the route
    }
    unsubscribe(); // stop listening for changes after handling the navigation
  });
});

  export { app_router };
