import {doc, getDoc, setDoc } from "firebase/firestore"
import { db } from "@/firebase_init";
import { store } from "@/store";
import { createDefaultPersonalData, PersonalData } from "../defs";

const docId = "pData";

function getCollectionName() {
    return 'users/' + store.state.user.uid + '/pData'
}


async function updatePersonalDatatDb(pData) {
    const ref = doc(db, getCollectionName(),docId).withConverter(PersonalDataConverter);
    await setDoc(ref, new PersonalData(pData.firstname,pData.name, pData.email,pData.phone,pData.urgencyPhone));
}


async function loadPersonalDataDb() {
    const ref = doc(db, getCollectionName(), docId).withConverter(PersonalDataConverter);
    const docSnap = await getDoc(ref, );
    if (docSnap.exists()) {
        return docSnap.data()
    }
    return createDefaultPersonalData()
}

const PersonalDataConverter = {
    toFirestore: (pData) => {
        return {
            firstname: pData.firstname,
            name: pData.name,
            email: pData.email,
            phone: pData.phone,
            urgencyPhone: pData.urgencyPhone
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new PersonalData(data.firstname, data.name, data.email, data.phone, data.urgencyPhone);
    }
};

export { updatePersonalDatatDb, loadPersonalDataDb }
