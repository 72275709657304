class FDay {
    constructor(fdate, ftopic, fplace, available_seats, min_nb_person) {
        this.date = fdate;
        this.topic = ftopic; // e.g "grands vols", "pente école","marche et vol", ...
        this.place = fplace;
        this.is_cancelled = false;
        this.available_seats = available_seats;
        this.min_nb_person = min_nb_person;
    }
    cancelDay() {
        this.is_cancelled = true;
    }

    uncancelDay() {
        this.is_cancelled = false;
    }

    isEnoughEnrollment(nbFreeSeats) {
        return (this.available_seats - nbFreeSeats) >= this.min_nb_person;
    }
}

class FDiary {
    constructor(fdate, takeoff_place, landing_place, duration_minutes, comments) {
        this.flight_date = fdate;
        this.takeoff_place = takeoff_place,
            this.landing_place = landing_place;
        this.duration_minutes = duration_minutes;
        this.comments = comments;
    }
}

class MaterialData {
    constructor(manufacturer, model, color, ptv, rescue_manufacturer, rescue_model, rescue_ptv, rescue_date) {
        this.pg_manufacturer = manufacturer;
        this.pg_model = model;
        this.pg_color = color;
        this.pg_ptv = ptv;
        this.rescue_manufacturer = rescue_manufacturer;
        this.rescue_model = rescue_model;
        this.rescue_ptv = rescue_ptv;
        this.rescue_date = rescue_date;
    }
}

class PersonalData {
    constructor(firstname, lastname, email, phone, urgencyPhone) {
        this.firstname = firstname;
        this.name = lastname;
        this.email = email;
        this.phone = phone;
        this.urgencyPhone = urgencyPhone;
    }
}

class MissionData {
    constructor(selected) {
        this.selected = selected
    }
}

function createDefaultPersonalData() {
    return new PersonalData('', '', '', '', '');
}

function createDefaultMaterialData() {
    return new MaterialData('', '', '', '', '', '','','');
}

function createDefaultMissionData() {
    return new MissionData([])
}

export { FDay, FDiary, MaterialData, PersonalData, MissionData, createDefaultPersonalData, createDefaultMaterialData, createDefaultMissionData }

