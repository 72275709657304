import { createStore } from 'vuex';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import * as Sentry from "@sentry/browser";



const auth = getAuth();

const store = createStore({
    state() {
        return {
            user: null,
        }
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
            if (user) {
                Sentry.setUser({ email: user.email, id: user.id });
            } else {
                Sentry.setUser(null);
            }

        },
    },
    actions: {
        initAuth({ commit }) {
            onAuthStateChanged(auth, (user) => {
                commit('SET_USER', user);
            });
        },
    },
});

export { store }