<!-- eslint-disable -->
<template>
    <v-data-table :headers="translateHeaders" :items=flight_list
        :sort-by.sync="[{ key: 'flight_date', order: 'desc' }]">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t("fdiary.title") }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" width="auto">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" prepend-icon="mdi-folder-plus" variant="tonal">
                            {{ $t("fdiary.add_flight") }}
                        </v-btn>
                    </template>
                    <FlightAdderDialog v-model="editedItem" :isEdit="editId != null" @submit="insertOrUpdateFlight"
                        @cancel="cancel_add">
                    </FlightAdderDialog>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">{{ $t("fdiary.confirmation_title") }}</v-card-title>
                        <v-card-text>{{ $t("fdiary.confirmation_delete") }}</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
                            <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon class="me-2" size="small" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon size="small" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
            {{ $t("fdiary.empty_msg") }}
        </template>
    </v-data-table>
</template>

<script>
import FlightAdderDialog from '@/components/FlightAdderDialog.vue';
import { deleteFlightDb, insertOrUpdateFlightDb, loadFlightsDb } from '@/helpers/db/fDiaryDb'
import { FDiary } from '@/helpers/defs'

const empty_flight = {
    flight_date: new Date(),
    menu: false,
    takeoff_place: '',
    landing_place: '',
    duration_minutes: 10,
    comments: '',
}

export default {
    components: {
        FlightAdderDialog,
    },
    data() {
        return {
            dialog: false,
            dialogDelete: false,
            editedItem: new FDiary(new Date(),'','',10,''),
            flight_list: []
        }
    },
    async created() {
        await this.getFlights();
    },

    methods: {
        insertOrUpdateFlight() {
            insertOrUpdateFlightDb(this.editedItem, this.editId).then(() => { this.loading = false })
            this.close()
        },
        cancel_add() {
            this.close()
        },
        async getFlights() {
            this.flight_list = await loadFlightsDb()
        },
        format_date(date_iso_str) {
            return new Date(date_iso_str).toLocaleDateString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
        },
        editItem(item) {
            this.editedItem = Object.assign({}, item)
            this.editId = item.id
            this.dialog = true
        },

        deleteItem(item) {
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            deleteFlightDb(this.editedItem.id).then(() => { this.closeDelete() })
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = empty_flight
                this.editId = null
                this.getFlights()
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = empty_flight
                this.editId = null
                this.getFlights()
            })
        },

    },
    computed: {
        translateHeaders() {
            return [
                { title: this.$t('fdiary.table.date'), key: 'flight_date' },
                { title: this.$t('fdiary.table.takeoff'), key: 'takeoff_place' },
                { title: this.$t('fdiary.table.landing'), key: 'landing_place' },
                { title: this.$t('fdiary.table.flighttime'), key: 'duration_minutes' },
                { title: this.$t('fdiary.table.comments'), key: 'comments' },
               // { title: this.$t('fdiary.table.instructor'), key: 'instructor' },
                { title: this.$t('fdiary.table.actions'), key: 'actions' },
            ]
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
}

</script>

<style>
/* Custom class to align text to the left */
.text-left {
    text-align: left;
}
</style>