<template>
    <v-container>
        <v-row>
            <v-col :key="item.id" v-for="item in fdays">
                <v-card class="mx-auto" max-width="344">

                    <v-card-title>
                        {{ formatfDate(item.date) }}
                    </v-card-title>

                    <v-card-subtitle>
                        {{ item.topic }} {{ $t("flight_day.at") }} {{ item.place }}
                    </v-card-subtitle>

                    <v-card-text>
                        {{ get_free_seats(item) }} {{ $t("flight_day.free_seats") }}
                    </v-card-text>

                    <v-card-actions>
                        <v-btn @click="item.amienrolled = !item.amienrolled" variant="outlined"> {{ getbtntext(item)
                            }}</v-btn>
                        <v-chip v-if="item.is_cancelled" color="red" variant="flat"
                            prepend-icon="mdi-close">{{ $t("flight_day.cancelled") }}</v-chip>
                        <v-chip v-else-if="is_enough_enrollment(item)" color="green" variant="flat"
                            prepend-icon="mdi-checkbox-marked-circle">{{ $t("flight_day.confirmed") }}</v-chip>
                        <v-chip v-else color="grey" variant="flat"> {{ $t("flight_day.not_enough") }}</v-chip>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { loadFlightDaysDb } from '@/helpers/db/dayBookingDb';
import { useDate } from 'vuetify'

export default {
    data() {
        return {
            show: false,
            fdays: [],
        }
    },

    methods: {
        formatfDate(fdate){
            return useDate().format(fdate,"fullDateWithWeekday")
        },
        is_enough_enrollment(item) {
            return (item.available_seats - this.get_free_seats(item)) >= item.min_nb_person
        },
        getbtntext(item) {
                return item.amienrolled ? this.$t("flight_day.unenroll") : this.$t("flight_day.enroll")
        },
        get_free_seats(item) {
            return (item.amienrolled) ? item.available_seats - 1 : item.available_seats
        }
    },
    mounted() {
        loadFlightDaysDb().then((data) => { this.fdays = data });
    },
}
</script>