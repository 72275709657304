<template>
    <v-footer app class="bg-grey-lighten-1">
        <v-row justify="center" no-gutters>
            <v-btn class="mx-2" rounded="xl" variant="text" to="/datapolicy" link>{{ $t("footer.legal") }}</v-btn>
            <v-col class="text-center mt-4" cols="12">
                {{ new Date().getFullYear() }} — <strong>Chablevasion</strong>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    data: () => ({
    }),
}
</script>