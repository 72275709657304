import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore"
import { formatDate } from 'date-fns';
import { db } from "@/firebase_init";

const collName = "fDays";

async function insertOrUpdateFlightDayDb(flight, is_edit) {
    flight.flight_date = formatDate(flight.flight_date, "yyyy-MM-dd")
    if (is_edit == null) {
        // Insert a new record
        await addDoc(collection(db, collName), flight)
    } else {
        //edit current record
        await updateDoc(doc(db, collName, flight.id), flight)
    }
}

async function deleteFlightDayDb(fid) {
    deleteDoc(doc(db, collName, fid))
}

async function loadFlightDaysDb() {
    const docRef = collection(db, collName);
    const snapshot = await getDocs(docRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export { insertOrUpdateFlightDayDb, deleteFlightDayDb, loadFlightDaysDb }