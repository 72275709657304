import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";


const fbapp = initializeApp({
    apiKey: "AIzaSyBc108dGA4AzXqKFtP2lKjZ_0BatKDkU4w",
    authDomain: "fsvl-12891.firebaseapp.com",
    databaseURL: "https://fsvl-12891-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "fsvl-12891",
    storageBucket: "fsvl-12891.appspot.com",
    messagingSenderId: "969850865165",
    appId: "1:969850865165:web:629c365259796645a5b744",
    measurementId: "G-BNCZH0B5ZY"

})


const db = getFirestore(fbapp);
const analytics = getAnalytics(fbapp);
export { db, fbapp, analytics };
