import { createApp } from 'vue'
import '@mdi/font/css/materialdesignicons.css'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
// Translations provided by Vuetify and locales
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { createI18n, useI18n } from 'vue-i18n'
import { fr, en, de } from 'vuetify/locale'
// import translations
import my_fr from "@/locales/my_fr.json";
import my_de from "@/locales/my_de.json";
import my_en from "@/locales/my_en.json";

import App from './App.vue'
import { app_router } from './router'

import * as Sentry from "@sentry/vue";


const messages = {
    en: {
        $vuetify: {
            ...en
        },
        ...my_en
    },
    fr: {
        $vuetify: {
            ...fr
        },
        ...my_fr
    },
    de: {
        $vuetify: {
            ...de
        },
        ...my_de
    }
}

// configure i18n
const i18n = createI18n({
    legacy: false,
    locale: "fr",
    fallbackLocale: "en",
    messages: messages,
});


const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },

    },
    locale: {
        adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
})


import { store } from './store';


store.dispatch('initAuth'); // early init of firebase authentication
const app = createApp(App)
app.use(app_router)
app.use(vuetify)
app.use(i18n)
app.use(store)

Sentry.init({
    app,
    dsn: "https://564fce6df360c3ddb38749a1bc600d9c@o4508138565140480.ingest.de.sentry.io/4508138566844496",
    integrations: [
        Sentry.browserTracingIntegration({ app_router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount('#app')
