<template>
    <v-container>
        <p>{{ $t("dashboard.welcome_message1") }}</p>
        {{ $t("dashboard.welcome_message2") }}
    </v-container>
</template>


<script>

</script>
