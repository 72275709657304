<!-- eslint-disable -->
<template>
    <v-app-bar app fixed dark scroll-behavior="collapse" :elevation="5">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="grey-text" v-if="user || smAndDown"></v-app-bar-nav-icon>
        <v-app-bar-title>{{ $t("menu.title") }}</v-app-bar-title>
        <template v-if="mdAndUp">
            <v-btn v-if="user == null" @click="$router.push('/login')">{{ $t("menu.signin") }}</v-btn>
            <v-btn v-if="user == null" @click="$router.push('/register')">{{ $t("menu.register") }}</v-btn>
            <v-spacer></v-spacer>
            <LocaleChanger></LocaleChanger>
            <v-spacer></v-spacer>
        </template>


    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" persistent v-if="user || smAndDown">
        <!-- <v-list-item prepend-avatar="https://randomuser.me/api/portraits/men/78.jpg" title="John Leider"></v-list-item> -->
        <v-divider></v-divider>
        <v-list density="compact" nav>
            <span v-if="user">
                <v-list-item v-for="item in loggedin_items" :key="item.title" :to="item.route" link>
                    <v-icon>{{ item.icon }}</v-icon>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
            </span>
            <span v-else>
                <v-list-item v-for="item in loggedout_items" :key="item.title" :to="item.route" link>
                    <v-list-item-title>{{ $t(item.title).toUpperCase() }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <LocaleChanger></LocaleChanger>
                </v-list-item>
            </span>
        </v-list>
        <template v-slot:append v-if="user">
            <div class="pa-2">
                <v-btn block @click="logout">
                    Se déconnecter
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script setup>
import { useDisplay } from 'vuetify'
// Destructure only the keys you want to use
const { smAndDown, mdAndUp } = useDisplay()
</script>

<script>
import LocaleChanger from '@/components/particles/LocaleChanger.vue';
import { logoutUser } from '@/helpers/auth';
export default {
    components: {
        LocaleChanger,
    },
    name: 'navigationBar',
    data: () => ({
        drawer: true,
        signinMenu:true,
        loggedin_items: [
            { title: 'menu.dashboard', icon: 'mdi-view-dashboard', route: '/' },
            { title: 'menu.flightdays', icon: 'mdi-calendar-clock', route: '/myflightdays' },
            { title: 'menu.flightmissions', icon: 'mdi-check-bold', route: '/myflightmissions' },
            { title: 'menu.flightdiary', icon: 'mdi-notebook', route: '/myflightdiary' },
            { title: 'menu.flightmaterial', icon: 'mdi-paragliding', route: '/myflightmaterial' },
            { title: 'menu.personaldata', icon: 'mdi-account-circle', route: '/mypersonaldata' },
            // { title: 'menu.settings', icon: 'mdi-cog', route: '/settings' },
        ],
        loggedout_items: [
            { title: 'menu.signin', icon: 'mdi-account', route: '/login' },
            { title: 'menu.register', icon: 'mdi-account-plus', route: '/register' }
        ]
    }),
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    methods: {
        logout() {
            logoutUser().then(() => { this.$router.push('/login') })
        }
    },
    watch:{
    }
};
</script>


<style></style>