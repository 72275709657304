import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore"
import { formatDate } from 'date-fns';
import { db } from "@/firebase_init";
import { store } from "@/store";
import { FDiary } from "../defs";


function getCollectionName() {
    return 'users/' + store.state.user.uid + '/flights'
}


async function insertOrUpdateFlightDb(flight, is_edit) {
    flight.flight_date = formatDate(flight.flight_date, "yyyy-MM-dd")
    if (is_edit == null) {
        // Insert a new record
        const ref = collection(db, getCollectionName()).withConverter(fDiaryConverter);
        await addDoc(ref, new FDiary(flight.flight_date, flight.takeoff_place, flight.landing_place, flight.duration_minutes, flight.comments));
    } else {
        //edit current record
        const ref = doc(db, getCollectionName(),flight.id);
        await updateDoc(ref, flight);
    }
}

async function deleteFlightDb(fid) {
    deleteDoc(doc(db, getCollectionName(), fid))
}

async function loadFlightsDb() {
    const docRef = collection(db, getCollectionName());
    const snapshot = await getDocs(docRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

const fDiaryConverter = {
    toFirestore: (fdiary) => {
        return {
            flight_date: fdiary.flight_date,
            takeoff_place: fdiary.takeoff_place,
            landing_place: fdiary.landing_place,
            duration_minutes: fdiary.duration_minutes,
            comments: fdiary.comments
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new FDiary(data.flight_date, data.takeoff_place, data.landing_place,data.duration_minutes,data.comments);
    }
};

export { insertOrUpdateFlightDb, deleteFlightDb, loadFlightsDb }