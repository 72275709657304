<template>
    <div>
        <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
            <div class="text-subtitle-1 text-medium-emphasis"> {{ $t("login.title") }}</div>

            <v-text-field v-model="email" density="compact" :placeholder="$t('login.email')"
                prepend-inner-icon="mdi-email-outline" variant="outlined"></v-text-field>
            <v-text-field v-model="password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visible ? 'text' : 'password'" density="compact" :placeholder="$t('login.pwd')"
                prepend-inner-icon="mdi-lock-outline" variant="outlined"
                @click:append-inner="visible = !visible"></v-text-field>


            <v-btn block class="mb-8" size="large" variant="outlined" @click="submit">
                {{ $t('login.login') }}
            </v-btn>
            <v-btn color="blue" size="small" variant="text" @click="forgotPassword">
                {{ $t('login.forgot_pwd') }}
            </v-btn>

            <v-card v-if="connectionErrorMessage" color="error">
                <v-card-text>{{ connectionErrorMessage }}</v-card-text>
            </v-card>
            <v-card v-if="resetEmailSent == true" color="confirm">
                <v-card-text> {{ $t('login.recovery_sent_msg') + email }}</v-card-text>
            </v-card>

            <v-card-text class="text-center">
                <v-btn color="blue" size="small" variant="text" append-icon="mdi-chevron-right"
                    @click="$router.push('/register')">
                    {{ $t('login.create_account') }}
                </v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import * as Sentry from "@sentry/browser";

export default {
    data: () => ({
        visible: false,
        email: '',
        password: '',
        connectionErrorMessage: null,
        resetEmailSent: null,
    }),
    methods: {
        submit() {
            this.connectionErrorMessage = null
            this.resetEmailSent = null
            const auth = getAuth()
            auth.languageCode = this.$i18n.locale
            signInWithEmailAndPassword(auth, this.email, this.password)
                .then(() => {
                    this.$router.push('/')
                })
                .catch((error) => {
                    switch (error.code) {
                        case 'auth/invalid-email':
                            this.connectionErrorMessage = this.$t("login.err_invalid_email")
                            break
                        case 'auth/user-not-found':
                            this.connectionErrorMessage = this.$t("login.err_user_not_found")
                            break
                        case 'auth/missing-password':
                            this.connectionErrorMessage = this.$t("login.err_missing_pwd")
                            break
                        case 'auth/wrong-password':
                            this.connectionErrorMessage = this.$t("login.err_wrong_pwd")
                            break
                        case 'auth/invalid-credential':
                            this.connectionErrorMessage = this.$t("login.err_invalid_credentials")
                            break;
                        default:
                            this.connectionErrorMessage = this.$t("login.err_unexpected")
                            Sentry.captureException(error);
                            break
                    }
                });
        },
        forgotPassword() {
            this.connectionErrorMessage = null
            this.resetEmailSent = null
            const auth = getAuth()
            auth.languageCode = this.$i18n.locale
            sendPasswordResetEmail(getAuth(), this.email).then(() => {
                this.resetEmailSent = true
            }).catch((error) => {
                switch (error.code) {
                    case 'auth/missing-email':
                        this.connectionErrorMessage = this.$t("login.err_missing_recovery_email")
                        break;
                    default:
                        this.connectionErrorMessage = this.$t("login.err_unexpected_recovery")
                        Sentry.captureException(error);
                        break;
                }
            })
        }
    }
}
</script>