import { doc, getDoc, setDoc } from "firebase/firestore"
import { db } from "@/firebase_init";
import { store } from "@/store";
import { createDefaultMissionData, MissionData } from "../defs";

const docId = "missionData";

function getCollectionName() {
    return 'users/' + store.state.user.uid + '/missionData'

}

async function updateMissionDatatDb(mData) {
    const ref = doc(db, getCollectionName(),docId).withConverter(MissionDataConverter);
    await setDoc(ref, new MissionData(mData.selected));
}


async function loadMissionDataDb() {
    const ref = doc(db, getCollectionName(), docId).withConverter(MissionDataConverter);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
        return docSnap.data()
    }
    return createDefaultMissionData()
}

const MissionDataConverter = {
    toFirestore: (mission) => {
        return {
            selected: mission.selected,
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new MissionData(data.selected);
    }
};


export { updateMissionDatatDb, loadMissionDataDb }